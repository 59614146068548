'use strict';

import 'fontawesome';
import $ from 'jquery';
import L from 'leaflet';
import 'bootstrap/js/dist/tooltip';

import MapUtils from './labeller/map-utils';

/* jshint ignore:start */
const platform_settings = JSON.parse(
  document.getElementById("platform-settings")?.textContent ?? "{}"
);
/* jshint ignore:end */
/* global platform_settings */

function setup_maps() {
  // Default to canvas
  const renderer = platform_settings.renderer ?? 'canvas';  // jshint ignore:line
  /* global renderer */

  $('.project__map').each((idx, el) => {
    const project_pk = $(el).data('project-pk');

    $.getJSON(`/api/project/${project_pk}/boundary/`, (data) => {
      if (!data.boundary) {
        return;
      }

      let map_options = {
        scrollWheelZoom: false,
        zoomControl: false,
      }

      if(renderer === 'svg') {
        map_options.renderer = L.svg();
        map_options.preferCanvas = false;
      }
      else if(renderer === 'canvas') {
        map_options.renderer = L.canvas();
        map_options.preferCanvas = true;
      }

      const map = L.map(el, map_options);
      const layer = L.geoJSON(data.boundary);
      layer.addTo(map);

      map.fitBounds(layer.getBounds());
      MapUtils.add_esri_basemap(map);
    });
  });
}

$(() => {
  setup_maps();

  $('[data-toggle="tooltip"]').tooltip();
});
