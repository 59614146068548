'use strict';

import L from 'leaflet';
import { basemapLayer, imageMapLayer } from 'esri-leaflet';

// leaflet-draw provides L.GeometryUtil
import 'leaflet-draw';

import { fetchJSON } from '../components/fetch';

const MapUtils = {
    IMAGERY_LABELS_KEY: 'Esri Imagery Labels',
};

let basemaps = {};
let overlays = {};
let arcgis_token = {
  value: null,
  expiry_time_s: -1
};

MapUtils.get_arcgis_token = () => fetchJSON('/portal/arcgis_token');

/**
 * Updates the access token for those basemaps that
 * requires it if they have expired.
 */
MapUtils.refresh_basemap_access_tokens = () => {
  let now_s = Date.now() / 1000;

  // b/c the expiry time returned by the server doesn't include
  // the expiry grace period when this check fails is token is still
  // valid for some time yet
  if (arcgis_token.expiry_time_s < now_s) {
    return MapUtils.get_arcgis_token()
      .then(data => {
        arcgis_token.value = data.token;
        arcgis_token.expiry_time_s = now_s + data.expiry;

        Object.keys(basemaps).forEach(name => {
          const layer = basemaps[name];
          if (layer.bce && layer.bce.needs_arcgis_token) {
            layer.authenticate(arcgis_token.value);
            console.log('ARCGIS TOKEN: REFRESH');
          }
        });
      });
  }
  else {
    return Promise.resolve();
  }
};


MapUtils.add_esri_basemap = (map) => {
  let esri_imagery = basemapLayer('Imagery', {
    maxNativeZoom: 17,
    maxZoom: 21,
    zIndex: -1000,
  });
  esri_imagery.addTo(map);
};


/**
 * Sets up basemaps in the given leaflet map.  When add_basemap is true Esri
 * Imagery basemap along with labels will be added to the map. Otherwise the
 * user will have to use the layer tool to enable these layers explicitly.
 *
 * Additional overlays can be specified via opts.additional_overlays which
 * is expected to be an object with the key being the name of the overlay
 * and the value a leaflet layer, e.g.
 *
 * opts.additiona_overlays = {
 *   'Customer Overlay 1': leaflet_layer,
 * };
 */
MapUtils.setup_basemaps = (map, add_basemap, opts={}) => {
  let basemap_pane = map.createPane('basemaps');
  basemap_pane.style.zIndex = -1000;

  let basemapopts = {
    maxZoom: 21,
    zIndex: -1000,
    pane: basemap_pane,
  };

  // basemaps
  let none_layer = L.tileLayer('', {id: 'none'});

  let esri_imagery = basemapLayer('Imagery', {
    id: 'esri-imagery',
    maxNativeZoom: 17,
    ...basemapopts
  });

  let esri_imagery_firefly = basemapLayer('ImageryFirefly', {
    id: 'esri-imagery-firefly',
    maxNativeZoom: 17,
    ...basemapopts
  });

  let esri_landsat_ps = imageMapLayer({
    id: 'landsat-8-pan-sharpened',
    url: 'https://landsat2.arcgis.com/arcgis/rest/services/Landsat8_PanSharpened/ImageServer',
    maxNativeZoom: 17,
    ...basemapopts
  });

  let esri_sentinel2 = imageMapLayer({
    id: 'sentinel-2',
    url: 'https://sentinel.arcgis.com/arcgis/rest/services/Sentinel2/ImageServer',
    maxNativeZoom: 17,
    ... basemapopts
  });
  esri_sentinel2.bce = {'needs_arcgis_token': true};

  let dendra_osm_standard = L.tileLayer('https://d226qtfeq6hsdh.cloudfront.net/{z}/{x}/{y}.png', {
    id: 'dendra-osm-standard',
    maxNativeZoom: 21,
    attribution: '(c) OpenStreetMap contributors',
    ... basemapopts
  });

  // overlays
  let esri_imagery_labels = basemapLayer('ImageryLabels');

  basemaps = {
    'None': none_layer,
    'OSM Standard': dendra_osm_standard,
    'Esri Imagery': esri_imagery,
    'Esri Imagery (Firefly)': esri_imagery_firefly,
    'Landsat 8 (Pan-sharpened)': esri_landsat_ps,
    'Sentinel 2': esri_sentinel2,
  };

  overlays = {
    [MapUtils.IMAGERY_LABELS_KEY]: esri_imagery_labels,
    ...opts.additional_overlays,
  };

  L.control.layers(basemaps, overlays, {autoZIndex: false}).addTo(map);

  // add our own icon
  let ele = document.getElementsByClassName('leaflet-control-layers-toggle')[0];
  ele.innerHTML = '<i class="far fa-layer-group fa-lg"></i>';

  if (add_basemap) {
    esri_imagery_labels.addTo(map);
    esri_imagery.addTo(map);
  } else {
    none_layer.addTo(map);
  }
};

const hide_all = (map, layers) => {
  Object.keys(layers).forEach(name => {
    const layer = layers[name];
    if(map.hasLayer(layer)) {
      map.removeLayer(layer);
    }
  });
};

MapUtils.set_base_layers = (map, basemap_id, show_overlay) => {
  hide_all(map, basemaps);
  hide_all(map, overlays);

  const base_layer = Object.values(basemaps).find(layer => layer.options.id === basemap_id);
  if (base_layer) {
    base_layer.addTo(map);
  }

  if (show_overlay) {
    overlays[MapUtils.IMAGERY_LABELS_KEY]?.addTo(map); // jshint ignore:line
  }
};

/**
 * Returns the area bounded by layer in meter square, -1 if the area could not be determined.
 */
MapUtils.get_layer_area = layer => {
  let latlngs;
  if (layer._defaultShape) {
    latlngs = layer._defaultShape();
  }

  if (latlngs === undefined) {
    try {
      latlngs = layer.getLatLngs();
    } catch (e) {}
  }

  if (latlngs !== undefined && latlngs.length >= 3) {
    return L.GeometryUtil.geodesicArea(latlngs);
  } else if (layer.eachLayer !== undefined) {
    let total_area = 0;
    layer.eachLayer(function(ll) {
      function get_latlngs(coords) {
        let latlngs = [];
        for (let cdx = 0; cdx < coords.length; ++cdx) {
          if (Array.isArray(coords[cdx][0])) {
            latlngs = latlngs.concat(get_latlngs(coords[cdx]));
          } else {
            let pt = L.latLng(coords[cdx].slice(0, 2).reverse());
            latlngs.push(pt);
          }
        }

        return latlngs;
      }

      let geometry = ll.feature.geometry;

      let calcarea = geo => {
        if (geo.type.search('Point') < 0 && geo.type.search('Line') < 0) {
          let latlngs = get_latlngs(geo.coordinates);
          if (latlngs.length > 3) {
            total_area += L.GeometryUtil.geodesicArea(latlngs);
          }
        }
      };

      if (geometry.type === 'GeometryCollection') {
        geometry.geometries.forEach(geo => {
          calcarea(geo);
        });
      } else {
        calcarea(geometry);
      }

    });

    return total_area;
  } else {
    return -1;
  }
};


MapUtils.show_raster_layer = (map, layer_data, zoom_to_fit=true, add_to_map=true) => {
  let layer_url = layer_data.render_url;

  let layer = L.tileLayer(layer_url, {
      attribution: layer_data.attribution,
      // we always allow zooming up to level 26 so all layers can 'keep up'
      // with UHR layers. Otherwise layers will blink on and off as the user
      // zooms in which is very distracting.
      maxZoom: 26,
      // even though the 'native' zoom of the source may be less
      maxNativeZoom: layer_data.render_options.max_zoom,
      tms: true,
      layer_id: layer_data.id,
      access_token: layer_data.access_token,
      zIndex:layer_data.render_options.zindex,
  });

  if (add_to_map) {
    layer.addTo(map);
  }

  // center the map on the layer
  if (zoom_to_fit) {
    map.setView(
      L.latLng(layer_data.render_options.centre_coordinates),
      layer_data.render_options.initial_zoom || 16
    );
  }

  layer.bce = layer_data;

  return layer;
};

MapUtils.removeAll = map => map.eachLayer(layer => map.removeLayer(layer));

export default MapUtils;
